<template>
    <div>
        <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                background-color="#000000"
                text-color="#ffffff"
                active-text-color="#ffd04b"
                router
                :collapse-transition="false">
            <el-menu-item index="/agent/agent_main_statistic"
                          :route="{path:'/agent/agent_main_statistic',query:{'t':new Date().getTime()}}">
                Comission History
            </el-menu-item>
<!--            <el-menu-item index="/agent/agent_sub_list"-->
<!--                          :route="{path:'/agent/agent_sub_list',query:{'t':new Date().getTime()}}">-->
<!--                Distributor list-->
<!--            </el-menu-item>-->
            <el-menu-item index="/agent/agent_user_list"
                          :route="{path:'/agent/agent_user_list',query:{'t':new Date().getTime()}}">
                Member list
            </el-menu-item>
            <el-menu-item index="/agent/agent_recharge_list"
                          :route="{path:'/agent/agent_recharge_list',query:{'t':new Date().getTime()}}">
                Deposit History
            </el-menu-item>
            <el-menu-item index="/agent/agent_exchange_list"
                          :route="{path:'/agent/agent_exchange_list',query:{'t':new Date().getTime()}}">
                Withdraw History
            </el-menu-item>
<!--            <el-menu-item index="/agent/agent_subagent_exchange_list"-->
<!--                          :route="{path:'/agent/agent_subagent_exchange_list',query:{'t':new Date().getTime()}}">-->
<!--                Distributor Withdraw-->
<!--            </el-menu-item>-->

<!--            <el-menu-item index="/agent/agent_logcash_list"-->
<!--                          :route="{path:'/agent/agent_logcash_list',query:{'t':new Date().getTime()}}">-->
<!--                CASH로그-->
<!--            </el-menu-item>-->
            <el-menu-item index="/agent/agent_sportsbet"
                          :route="{path:'/agent/agent_sportsbet',query:{'t':new Date().getTime()}}">
                Sports Bet History
            </el-menu-item>
            <el-menu-item index="/agent/agent_casino_honor_bet"
                          :route="{path:'/agent/agent_casino_honor_bet',query:{'t':new Date().getTime()}}">
                Casino Bet History
            </el-menu-item>



<!--            <el-menu-item index="/agent/agent_move_cash"-->
<!--                          :route="{path:'/agent/agent_move_cash',query:{'t':new Date().getTime()}}">-->
<!--                Money transfer-->
<!--            </el-menu-item>-->
<!--            <el-menu-item index="/agent/agent_cash_exchange"-->
<!--                          :route="{path:'/agent/agent_cash_exchange',query:{'t':new Date().getTime()}}">-->
<!--                Withdraw-->
<!--            </el-menu-item>-->

            <el-menu-item index="/agent/logout" @click="logout" style="color: red">
                Sign Out
            </el-menu-item>
        </el-menu>
    </div>


</template>

<script>

    import {agentMixin} from "../../common/agent/agentMixin";
    import {agentLogout, getAgentById} from "../../network/agent/commonRequest";

    export default {
        name: "AgentTopbarComp",
        components: {},
        mixins: [agentMixin],
        data() {
            return {
                activeIndex: '1',
            }
        },
        methods: {
            logout() {
                agentLogout();
                this.$router.push({path: '/partner'})
            }

        },
        computed: {},
        created() {
            getAgentById(-1).then(res => {
                this.$store.state.agent.agentInfo = res.data.data;
            })
        },
        mounted() {

        },
        watch: {},
    }
</script>

<style scoped>
    .header {
        background-color: #143771;
    }

    .el-menu {
        border: 0 solid transparent !important;
        font-weight: bold;
    }

    .tick {
        position: absolute;
        border-radius: 5px;
        font-size: 10px;
        top: 5px;
        right: 2px
    }

    .vp {
        position: absolute;
        top: -1000px;
    }

    .countAni {
        animation: 1s ease-in-out 0s infinite normal none running twinkling;
    }


    @keyframes twinkling {

        0% {
            opacity: 0.4;
        }

        100% {
            opacity: 1;
        }
    }
</style>